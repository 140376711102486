import { useQuery, useMutation, MutationOptions, QueryOptions } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';
import * as api from './api';
import { handleLogout } from 'domain/utils';

export function useGetSpecificFinancialProviderAccountQuery(
  token: string,
  uuid: string,
  options?: QueryOptions<AxiosResponse<any>, AxiosError>
) {
  return useQuery(
    ['financialProviderAccount', uuid],
    () => api.getSpecificFinancialProviderAccount(token, uuid),
    {
      onError: () => handleLogout(),
      ...options,
    }
  );
}
export function useDeleteSpecificFinancialProviderAccountMutation(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, { token: string; uuid: string }>
) {
  return useMutation(({ token, uuid }) => api.deleteSpecificFinancialProviderAccount(token, uuid), {
    onError: () => handleLogout(),
    ...options,
  });
}

export function useRefreshSpecificFinancialProviderAccountMutation(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, { token: string; uuid: string }>
) {
  return useMutation(({ token, uuid }) => api.refreshSpecificFinancialProviderAccount(token, uuid), {
    onError: () => handleLogout(),
    ...options,
  });
}

export function useGetFinancialInsightsQuery(
  token: string,
  options?: QueryOptions<AxiosResponse<any>, AxiosError>
) {
  return useQuery('financialInsights', () => api.getFinancialInsights(token), {
    onError: () => handleLogout(),
    ...options,
  });
}
export function useGetFinancialAccountsQuery(
  token: string,
  options?: QueryOptions<AxiosResponse<any>, AxiosError>
) {
  return useQuery('financialAccounts', () => api.getFinancialAccounts(token), {
    onError: () => handleLogout(),
    enabled: false,
    ...options,
  });
}

export function useGetSpecificFinancialAccountQuery(
  token: string,
  uuid: string,
  options?: QueryOptions<AxiosResponse<any>, AxiosError>
) {
  return useQuery(['financialAccount', uuid], () => api.getSpecificFinancialAccount(token, uuid), {
    onError: () => handleLogout(),
    enabled: false,
    ...options,
  });
}

export function useUpdateSpecificFinancialAccountMutation(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, { token: string; uuid: string }>
) {
  return useMutation(({ token, uuid }) => api.updateSpecificFinancialAccount(token, uuid), {
    onError: () => handleLogout(),
    ...options,
  });
}
