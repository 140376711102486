import { Card, Button } from 'domain/common/components';
import { useTokenFromUrl } from 'domain/hooks';
import { useAuthStore } from 'domain/state';

export const VerifiedEmailCard = () => {
  const { verifiedUserData } = useTokenFromUrl();
  const setUser = useAuthStore((state) => state.setUser);

  return (
    <Card title="Great!" message="Your email has been verified." iconPosition="top">
      <Button
        onClick={() => {
          verifiedUserData && setUser(verifiedUserData);
        }}
        intent="accent-dark"
        type="submit"
        className="mt-8 w-full rounded  px-4 py-2 text-3xl font-semibold   subpixel-antialiased  xs:text-xl md:text-xl   "
      >
        Go to My Zembl Dashboard
      </Button>
    </Card>
  );
};
