import { isNil } from 'lodash';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type User = {
  id?: number;
  contact_no?: string;
  salutation?: string | null;
  first_name?: string;
  middle_name?: string | null;
  last_name?: string;
  email?: string;
  created_at?: string;
  updated_at?: string;
  auth_token?: string;
  auth_token_expiry?: string;
  success?: boolean;
};
type ContactDetails = {
  uuid: any;
  contact_no: string;
  salutation: string | null;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  email: string;
};

type Store = {
  token?: string;
  isAuthenticated: boolean;
  user?: User;
  urlToken?: string;
  contactDetails: ContactDetails | null;
  setContactDetails: (details: any) => void;
  setUser: (user: User | null) => void;
  setUrlToken: (urlToken: string | null) => void;
  resetCache: () => void;
};

const initialState: Store = {
  token: null,
  isAuthenticated: false,
  contactDetails: null,
  user: null,
  setUrlToken: () => {},
  setContactDetails: () => {},
  setUser: () => {},
  resetCache: () => {},
};
export const useAuthStore = create<Store>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setContactDetails: (details) => {
          set({ contactDetails: details });
        },
        setUrlToken: (urlToken) => {
          set({ urlToken });
        },
        resetCache: () => {
          set(() => {
            window.localStorage.removeItem('auth-store');
            return { isAuthenticated: false };
          });
        },

        setUser: (user) => {
          set(() => {
            if (!user?.auth_token) {
              window.localStorage.removeItem('auth-store');
              return { contactDetails: null, urlToken: null, user: null };
            }
            return { user, isAuthenticated: !isNil(user?.auth_token) ? true : false };
          });
        },
      }),
      {
        name: 'auth-store', // unique name
        getStorage: () => window.localStorage, // (optional) by default the 'localStorage' is used
      }
    )
  )
);
