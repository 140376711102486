import classNames from 'classnames';
import { TextareaHTMLAttributes } from 'react';

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;
export function Textarea({ rows = 5, ...props }: TextareaProps) {
  return (
    <textarea
      className={classNames(
        'rounded border   bg-white px-5 py-3 text-zembl-forest    placeholder:text-zembl-forest'
      )}
      {...props}
      rows={rows}
    />
  );
}
