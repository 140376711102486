import Glider from 'react-glider';
import { GreenLogoIcon, CheckCircleGreenIcon } from 'domain/assets';
import './header-carousel.scss';
import cn from 'classnames';

import { Button } from 'domain/common/components';
const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 2.8,
      itemWidth: 200,
    },
  },

  {
    breakpoint: 800,
    settings: {
      slidesToShow: 2.8,
      itemWidth: 200,
    },
  },
  {
    breakpoint: 700,
    settings: {
      slidesToShow: 2.8,
      itemWidth: 200,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 2.8,
      itemWidth: 200,
    },
  },
];

const borderStyles: any = {
  pending: 'pending-card-border',
  found: 'found-a-deal-card-border',
  finding: 'finding-a-deal-card-border',
  locked: 'locked-a-deal-card-border',
};

const textStyles: any = {
  pending: 'pending-card-text',
  found: 'found-a-deal-card-text',
  finding: 'finding-a-deal-card-text',
  locked: 'locked-a-deal-card-text',
};
const Card = () => {
  return (
    <div className="flex  flex-col items-center justify-center gap-1 overflow-hidden rounded-xl bg-zembl-teal xs:ml-0   xs:h-64 xs:w-64   xs:rounded-2xl md:h-101  md:w-101 md:gap-3 ">
      <div className="h-[63px]  w-[73px]">
        <GreenLogoIcon />
      </div>

      <div className="text-center text-xl capitalize leading-7 tracking-wider text-zembl-green xs:leading-9 md:text-2.8xl">
        Zembl Services
      </div>
      <div className="relative mt-2 whitespace-nowrap text-center text-lg leading-6 text-zembl-white xs:mt-3 xs:text-xl xs:leading-7 md:text-2xl">
        {`You've activated`}
      </div>
      <div className="md:leading-16 text-center text-3xl font-medium capitalize leading-8 text-zembl-white xs:text-4xl xs:leading-10 md:text-6xl">
        0
      </div>
      <div className="relative whitespace-nowrap text-center text-lg leading-6 text-zembl-white xs:text-xl xs:leading-7 md:text-2xl">
        of 4 available
      </div>
    </div>
  );
};

const CustomArrow = ({ direction, onClick }: any) => {
  const className = `custom-${direction}-arrow`;

  return <i className={className} onClick={onClick} />;
};

const DealCard = ({ energyType, cost, cardText, buttonText, status, iconUrl, buttonIntent }: any) => {
  const headerClass = cn(
    'tracking-custom relative mb-px text-center text-2xl capitalize leading-7 xs:leading-9 md:text-3xl md:leading-10',
    {
      'text-zembl-forest': status === 'found',
      'text-gray-400': status !== 'found',
    }
  );
  return (
    <div className="mr-2   flex  flex-col items-center justify-center gap-1 overflow-hidden rounded-xl bg-white  xs:h-64 xs:w-64 xs:gap-2 xs:rounded-2xl md:h-101 md:w-101 md:gap-3 ">
      <img src={iconUrl} className="w-8 min-w-0 xs:w-10" />
      <div className={headerClass}>{energyType}</div>
      <div className="md:leading-12 relative mb-px text-center text-3xl font-medium capitalize leading-8 text-black xs:leading-10 md:text-4xl">
        {cost}
      </div>
      <div className="relative whitespace-nowrap text-center text-base font-normal  leading-[1.75rem] text-zembl-forest xs:leading-6 md:leading-7">
        * Average saved for businesses like yours
      </div>

      <div className={borderStyles[status]}>
        <div className={textStyles[status]}>{cardText}</div>
      </div>
      {status === 'found' ? (
        <CheckCircleGreenIcon />
      ) : (
        <Button intent={buttonIntent}>
          <span className="text-center font-sequel text-[1.25rem] font-med-bold leading-[1.625rem] tracking-[0.05rem] text-zembl-white">
            {buttonText}
          </span>
        </Button>
      )}
    </div>
  );
};

const createDealCard = (
  energyType: any,
  iconUrl: any,
  cost: any,
  cardText: any,
  buttonText: any,
  status: any,
  buttonIntent: any
) => (
  <DealCard
    key={`${energyType}-card`}
    iconUrl={iconUrl}
    energyType={energyType}
    cost={cost}
    cardText={cardText}
    buttonText={buttonText}
    status={status}
    buttonIntent={buttonIntent}
  />
);

const currentDealCards = [
  createDealCard(
    'Electricity',
    'https://file.rendit.io/n/SoiqT0iCT6z2YkuGJElM.svg',
    '$987*',
    'Found YOU A BETTER DEAL',
    'Upload Electricity Bill',
    'found',
    'billUpload'
  ),
  createDealCard(
    'Gas',
    'https://file.rendit.io/n/XTffmTk4McyR3tBzqYdP.svg',
    '$170*',
    'GET A BETTER DEAL',
    'Upload gas bill',
    'locked',
    'bill-dark'
  ),
  createDealCard(
    'Solar Panels',
    'https://file.rendit.io/n/XTffmTk4McyR3tBzqYdP.svg',
    '$2,000*',
    'INTERESTED IN SOLAR?',
    'Make an enquiry',
    'locked',
    'bill-dark'
  ),
];

const CardCarousel = () => {
  return (
    <Glider
      responsive={responsive}
      iconRight={<CustomArrow direction="right" />}
      iconLeft={<CustomArrow direction="left" />}
      hasArrows
      draggable
      slidesToShow={2.8}
      slidesToScroll={0.2}
      scrollLock
    >
      {currentDealCards}
    </Glider>
  );
};
export const HeaderCarousel = () => {
  return (
    <div className=" flex w-full  justify-center xs:flex-col xs:px-8   sm:px-20  md:px-24  lg:flex-row lg:px-44">
      <div className="mr-2">
        <Card />
      </div>
      <div className="xs:w-100 sm:w-100  md:w-165 lg:w-full">
        <CardCarousel />
      </div>
    </div>
  );
};
