import React from 'react';
import { Button, TextHighlight } from 'domain/common/components';
import { solar } from '../../../common/constants/offers.constants';
import { OfferContentCards } from '.';

type SolarEnergyContentProps = {
  onEnquire: () => void;
};

export const SolarEnergyContent = ({ onEnquire }: SolarEnergyContentProps) => {
  const { content, cta } = solar;
  const { title, description, img, cards } = content;
  return (
    <section className="container   mx-auto flex flex-col gap-16 bg-zembl-background  p-24 pt-12  ">
      <div className="flex flex-col items-center gap-14 pr-5 xs:flex-row  sm:flex-row md:flex-row">
        <div className="leading-1.5 flex flex-1 flex-col gap-8  px-80 text-1.25  tracking-0.05 text-zembl-forest   xs:px-12   sm:px-12">
          <h3 className="text-[2.75rem] font-med-thin   leading-[3.125rem] tracking-[0.0275rem]">
            {title}
          </h3>
          <TextHighlight
            text={description}
            boldPhrases={[
              'boost your bottom line',
              'appeal to customers and suppliers',
              'government rebates',
            ]}
          />
        </div>

        <div className="flex-1 ">
          <img {...img} className=" border-current" />
        </div>
      </div>

      <OfferContentCards className="h-107" cards={cards} />

      <Button
        intent="green"
        className="w-80 self-center text-xl font-med-bold text-zembl-forest"
        onClick={onEnquire}
      >
        {cta}
      </Button>
    </section>
  );
};
