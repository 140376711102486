import { useEffect, useState } from 'react';
import { ErrorScreen } from 'domain/common/components';
import { useFastLinkStore } from 'domain/state';

// Constants
const SCRIPT_SRC = 'https://cdn.yodlee.com/fastlink/v4/initialize.js';
const SCRIPT_DELAY = 500;
const CONFIG_NAME = 'Dev_Configuration';
const FAST_LANE_ERROR =
  'FastLink already in use, multiple instances of fastLink may not work as expected.';

const addFastLinkScript = (onLoadCallback: () => void): HTMLScriptElement => {
  const script = document.createElement('script');
  script.src = SCRIPT_SRC;
  script.type = 'text/javascript';
  script.onload = onLoadCallback;
  document.body.appendChild(script);
  return script;
};

const closeCurrentConnection = () => {
  if (window?.fastlink?.isConnected) {
    window?.fastlink?.close();
  }
};

export const UpdateAccount = ({ selectedUuid }: { selectedUuid: string }) => {
  const { fastLink } = useFastLinkStore();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    access_token: accessToken,
    fast_link_url: fastLinkURL,
    fast_link_config_name: configName,
  } = fastLink;

  const handleFastLinkConfiguration = () => {
    if (window.fastlink && !window.fastlink.isConnected) {
      window.fastlink.open(
        {
          fastLinkURL,
          accessToken: `Bearer ${accessToken}`,
          forceIframe: true,
          params: {
            configName: CONFIG_NAME,
            flow: 'edit',
            providerAccountId: selectedUuid,
          },
          onSuccess: (data: any) => console.info('onSuccess method called: => ', data),
          onError: (data: any) => {
            console.info('onError method called: => ', data);
            setErrorMessage(data?.message);
          },
          onClose: (data: any) => console.log('onExit method called: => ', data),
          onEvent: (obj: any) => {
            if (obj?.fnToCall === 'renewClientSession') {
              setErrorMessage(null);
            }
            console.log('onEvent method called: => ', obj);
            console.log('provider url=>');
          },
        },
        configName
      );
    }
  };

  useEffect(() => {
    const script = addFastLinkScript(() => {
      setTimeout(handleFastLinkConfiguration, SCRIPT_DELAY);
    });

    return () => {
      document.body.removeChild(script);
      closeCurrentConnection();
    };
  }, [accessToken, fastLinkURL, configName]);

  return (
    <div className="max-w-120 min-h-100  bg-white">
      {errorMessage && <ErrorScreen errorMessage={errorMessage} />}
      <div className="min-h-100 w-120  bg-white" id={configName}></div>
    </div>
  );
};
