import { useState } from 'react';
import cn from 'classnames';
import { loginForm } from 'domain/common/constants/existing-customer-login.constants';
import { Card, ControlledInput, Form, Button } from 'domain/common/components';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useCreatePasswordQuery } from 'domain/queries';
import { useAuthStore } from 'domain/state';

const passwordSchema = z.string().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/, {
  message:
    'Your password must contain 8 characters, 1 lowercase, 1 uppercase, 1 number and 1 special character',
});

const emailSchema = z.string().email({
  message: 'Invalid email address',
});
const existingCustomerFormSchema = z
  .object({
    email: emailSchema,
    password: passwordSchema,
    confirmPassword: passwordSchema,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match.',
    path: ['confirmPassword'], // Pointing the error to the confirmPassword field
  });
function useExistingCustomerLogin() {
  const urlToken = useAuthStore((state) => state.urlToken);
  const contactDetails = useAuthStore((state) => state.contactDetails);

  // Local state for the email
  const [email, setEmail] = useState(contactDetails?.email || '');

  const { mutateAsync: createPassword } = useCreatePasswordQuery({
    onSuccess: (data: any) => {
      setEmail(data.user.email);
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: email,
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(existingCustomerFormSchema),
    mode: 'onBlur',
  });

  const onSubmit = async (data: any, options?: any) => {
    try {
      await createPassword(
        { uuid: contactDetails?.uuid, password: data.password, token: urlToken },
        options
      );
    } catch (error) {
      console.error(error);
    }
  };

  return { control, handleSubmit, errors, onSubmit };
}

export const ExistingCustomSignIn = ({ onRegisterClick }: any) => {
  const { heading, button, termsAndConditions, alreadyRegistered, login } = loginForm.existingCustomer;
  const { control, handleSubmit, errors, onSubmit } = useExistingCustomerLogin();

  const handleFormSubmit = (formData: any) => {
    onSubmit(formData, {
      onSuccess: () => {
        onRegisterClick();
      },
      onError: (error: any) => {
        console.error(error);
      },
    });
  };

  const renderExistingCustomFields = () => {
    const { fields } = loginForm.existingCustomer;
    return Object.values(fields).map((field, index) => {
      const inputClass = cn('rounded border border-black h-10 ', {
        'bg-zembl-grey': field.disabled,
      });

      return (
        <ControlledInput
          key={`render-existing-custom-input${index}`}
          className="mb-4  w-full"
          inputClassName={inputClass}
          disabled={field.disabled}
          control={control}
          placeholder={field.label}
          {...field}
        />
      );
    });
  };

  return (
    <Form className="h-110  w-120 rounded-2xl" onSubmit={handleSubmit(handleFormSubmit)}>
      <h2 className="mb-8 text-center text-3xl  ">{heading}</h2>
      {renderExistingCustomFields()}
      <div className="my-4  ml-2 flex items-center">
        <input type="checkbox" id="checkbox" name="checkbox" />
        <label className="ml-4 font-sans" htmlFor="checkbox">
          {termsAndConditions}
        </label>
      </div>
      <Button intent="green" type="submit" className="w-full rounded  px-4 py-2 font-bold text-black ">
        {button}
      </Button>
      {Object.entries(errors).map(([field, error]) => (
        <div key={field}>
          Error in {field}: {error.message}
        </div>
      ))}
      <div className="mt-4 text-center text-xl  ">
        {alreadyRegistered}
        <a className=" text-violet-400" href="#/login">
          {login}
        </a>
      </div>
    </Form>
  );
};
