import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { loginFormSchema } from '../schema/login.schema';
import { useFormSubmitHookProps } from 'domain/common/models';
import { useAuthStore } from 'domain/state';
import { useSignInMutation } from 'domain/queries';
import { forgotPasswordFormSchema } from 'domain/schema';
import { useResetPasswordMutation } from 'domain/queries';

// Define the sign up steps constant
export const LOGIN_STEP = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  PASSWORD_RESET_CONFIRMATION: 'password-reset-confirmation',
} as const;

export function useForgotPasswordForm({ onSuccess }: useFormSubmitHookProps) {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(forgotPasswordFormSchema),
    mode: 'onBlur',
  });

  const { mutate: resetPassword } = useResetPasswordMutation({
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
  const [error] = useState('');
  const handleForgotPassword = handleSubmit((data) => {
    resetPassword(data);
  });
  return { control, onSubmit: handleForgotPassword, error, reset };
}
export function useLoginForm({ onSuccess }: useFormSubmitHookProps) {
  const setUser = useAuthStore((state) => state.setUser);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(loginFormSchema),
    mode: 'onBlur',
  });

  const { mutate } = useSignInMutation({
    onSuccess: (data) => {
      setUser(data.data);
    },
  });

  const [error] = useState('');
  const handleLogin = handleSubmit((data) => {
    //TODO put it back after the apis is fixed
    // mutate(data);
    setUser({ auth_token: 'test' });
  });

  return { control, onSubmit: handleLogin, error, reset };
}

export function useLogin() {
  const loginForm = useLoginForm({
    onSuccess: () => {},
  });

  return {
    loginForm: { ...loginForm },
  };
}
