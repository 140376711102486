import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const HEADERS = {
  'Content-Type': 'application/json',
  'X-API-KEY': API_KEY,
};

const api = axios.create({
  baseURL: API_URL,
  headers: HEADERS,
});

export const validateEmailToken = (token: string) => api.post('/email_verifications/verify', { token });
export const getFinancialProviderAccounts = (token: string) => {
  return api.get('/financial_provider_accounts', {
    headers: {
      Authorization: token,
    },
  });
};

export const getSpecificFinancialProviderAccount = (token: string, uuid: string) => {
  return api.get(`/financial_provider_accounts/${uuid}`, {
    headers: {
      Authorization: token,
      uuid: uuid,
    },
  });
};

export const deleteSpecificFinancialProviderAccount = (token: string, uuid: string) => {
  return api.delete(`/financial_provider_accounts/${uuid}`, {
    headers: {
      Authorization: token,
    },
  });
};

export const refreshSpecificFinancialProviderAccount = (token: string, uuid: string) => {
  return api.put(
    `/financial_provider_accounts/${uuid}/refresh`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export const getFinancialInsights = (token: string) => {
  return api.get('/financial_insights', {
    headers: {
      Authorization: token,
    },
  });
};

export const getFinancialAccounts = (token: string) => {
  return api.get('/financial_accounts', {
    headers: {
      Authorization: token,
    },
  });
};

export const getSpecificFinancialAccount = (token: string, uuid: string) => {
  return api.get(`/financial_accounts/${uuid}`, {
    headers: {
      Authorization: token,
      uuid: uuid,
    },
  });
};

export const updateSpecificFinancialAccount = (token: string, uuid: string) => {
  return api.put(`/financial_accounts/${uuid}`, {
    headers: {
      Authorization: token,
      uuid: uuid,
    },
  });
};

export const verifyEmail = (email: string) => api.post('/email_verifications', { email });

export const signIn = ({ email, password }: { email: string; password: string }) =>
  api.post('/sign_in', { email, password });

export const signOut = (token: string) => api.delete('/sign_out', { headers: { Authorization: token } });

export const authenticateUser = ({ token, data }: { token: string; data: any }) =>
  api.post('/auth', data, { headers: { Authorization: token } });

export const createFastLink = (accessToken: string) =>
  axios.post(`${API_URL}/fast_link`, null, {
    headers: { ...HEADERS, Authorization: accessToken },
  });

export const createInvitation = (contact_id: string) =>
  axios.post(`${API_URL}/invitations`, { contact_id }, { headers: HEADERS });

export const validateInvitation = (token: string) =>
  axios.post(`${API_URL}/invitations/validate`, { token }, { headers: HEADERS });
