import { useQuery, useMutation, QueryOptions, MutationOptions } from 'react-query';
import * as api from './api';
import { handleLogout } from 'domain/utils';
import { AxiosResponse, AxiosError } from 'axios';
export function useGetFinancialProviderAccountsQuery(
  token: string,
  options?: QueryOptions<AxiosResponse<any>, AxiosError>
) {
  return useQuery('financialProviderAccounts', () => api.getFinancialProviderAccounts(token), {
    onError: () => handleLogout(),
    enabled: false, // this ensures the query won't execute on mount
    ...options,
  });
}

export function useGetSpecificFinancialProviderAccountQuery(
  token: string,
  uuid: string,
  options?: QueryOptions<AxiosResponse<any>, AxiosError>
) {
  return useQuery(
    ['financialProviderAccount', uuid],
    () => api.getSpecificFinancialProviderAccount(token, uuid),
    {
      onError: () => handleLogout(),
      enabled: false,
      ...options,
    }
  );
}

export function useDeleteSpecificFinancialProviderAccountMutation(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, { token: string; uuid: string }>
) {
  return useMutation(({ token, uuid }) => api.deleteSpecificFinancialProviderAccount(token, uuid), {
    onError: () => handleLogout(),
    ...options,
  });
}
