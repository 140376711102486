import cn from 'classnames';
export type OfferContentCardsProps = {
  cards: { Icon: any; title: string; description: string }[];
  className?: any;
};
export function OfferContentCards({ cards, className }: OfferContentCardsProps) {
  return (
    <div className="mb-8 flex    flex-col   items-center justify-center gap-24 xs:flex-col sm:flex-col md:flex-row">
      {cards.map(({ Icon, title, description }, index) => {
        return (
          <div
            key={`${title}-${index}`}
            className={cn(
              'flex  w-90 flex-col  items-center rounded-2xl border border-current bg-zembl-white  text-center font-sequel font-166  text-zembl-forest',
              className
            )}
          >
            <div className="my-4 flex h-12 w-12 justify-center">
              <Icon />
            </div>
            <div className="flex h-20  px-2 text-2xl font-semibold">{title}</div>
            <div className="mx-2 text-1.25 font-158 text-zembl-forest ">{description}</div>
          </div>
        );
      })}
    </div>
  );
}
