import { useMutation, MutationOptions } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useAuthStore } from 'domain/state';
import * as api from './api';

export function useVerifyEmailQuery(options?: MutationOptions<AxiosResponse<any>, AxiosError, any>) {
  return useMutation(
    async (data) => {
      return await api.verifyEmail(data);
    },
    {
      ...options,
    }
  );
}

export function useValidateEmailTokenQuery(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, any>
) {
  return useMutation(async (data) => await api.validateEmailToken(data), options);
}

export function useSignInMutation(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, { email: string; password: string }>
) {
  return useMutation(api.signIn, options);
}
export const useSignOutQuery = (options?: MutationOptions<any, any, void, any>) => {
  const authToken = useAuthStore((state) => state?.user?.auth_token);
  return useMutation(() => api.signOut(authToken), options);
};

export function useAuth(options?: MutationOptions<AxiosResponse<any>, AxiosError, any>) {
  return useMutation(
    async (data) => {
      return await api.authenticateUser(data);
    },
    {
      ...options,
    }
  );
}
