import cn from 'classnames';
import { login } from 'domain/common/constants/login.constants';
import { ControlledInput, Form, Button } from 'domain/common/components';
import { useForgotPasswordForm } from 'domain/hooks/use-login.hook';

export const ForgotPassword = ({ onResetPasswordClick }: any) => {
  const { heading, fields, button } = login.forgotPasswordForm;
  const { control, onSubmit, error, reset } = useForgotPasswordForm({
    onSuccess: (data) => {
      onResetPasswordClick(data);
    },
  });

  const inputClass = cn('rounded border border-black h-10 ');
  return (
    <Form
      className="flex h-110 w-120 flex-col items-center justify-center rounded-2xl"
      onSubmit={onSubmit}
      error={error}
    >
      <h2 className="mb-8 text-center text-3xl">{heading}</h2>

      {Object.values(fields).map((field) => (
        <ControlledInput
          className="mb-4  w-full"
          inputClassName={inputClass}
          key={field.name}
          control={control}
          placeholder={field.label}
          {...field}
        />
      ))}
      <Button
        intent="green"
        type="submit"
        className="w-full rounded px-4 py-2 text-xl font-med-bold text-black	 subpixel-antialiased   md:text-xl"
      >
        {button}
      </Button>
    </Form>
  );
};
