import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Modal, PageLayout, Text, Form, Button, ControlledInput } from 'domain/common/components';
import { useUpdateCurrentPasswordQuery } from 'domain/queries';
import { useAuthStore } from 'domain/state';
import { CheckCircleGreenIcon, checkCircleGreen } from 'domain/assets';
import { useToggle } from 'domain/hooks';

const passwordSchema = z
  .string()
  .or(z.literal(''))
  .or(z.undefined())
  .refine(
    (value) => value !== '' && value !== undefined,
    'Please complete all mandatory fields marked with *'
  )
  .refine((value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/.test(value), {
    message:
      'Your password must contain 8 characters, 1 lowercase, 1 uppercase, 1 number and 1 special character',
  });

const messageConfirmation = {
  icon: { src: checkCircleGreen, alt: 'Check circle' },
  resetPasswordHeading: ' Password Reset',
  resetPasswordSubheading:
    'If you forget your password, you can reset at any time. We recommend regularly updating your password.',
};

const SecuritySettingsSchema = z
  .object({
    newPassword: passwordSchema,
    currentPassword: passwordSchema,
    confirmNewPassword: passwordSchema,
  })
  .refine((data) => data.confirmNewPassword === data.newPassword, {
    message: 'Passwords do not match.',
    path: ['confirmNewPassword'],
  });
export const SecuritySettings = () => {
  const header = 'Security';
  const subHeader = 'We recommend changing your password regularly for optimal security.';
  const { resetPasswordHeading, resetPasswordSubheading } = messageConfirmation;

  const [isResettingPassword, setIsResettingPassword] = useToggle(false);
  const authToken = useAuthStore((state) => state?.user?.auth_token);

  const { control, handleSubmit, formState } = useForm({
    resolver: zodResolver(SecuritySettingsSchema),
    mode: 'onBlur',
  });

  const { errors } = formState;
  console.log('errors', errors);
  const { mutate: updatePassword } = useUpdateCurrentPasswordQuery({
    onSuccess: () => {
      console.log('Password updated successfully');
      setIsResettingPassword();
    },
    onError: (err) => {
      console.log('Failed to update password', err);
    },
  });

  const handleChangePassword = (data: any) => {
    updatePassword({
      authorization: authToken,
      oldPassword: data?.currentPassword,
      password: data?.newPassword,
    });
  };

  return (
    <PageLayout className="bg-zembl-background" footerClass="bg-zembl-background">
      <div className="flex h-screen flex-col items-center  justify-start">
        <Text
          intent="heading"
          className="my-6 text-center font-sequel text-[2.75rem] font-med-thin leading-[3.125rem] tracking-[0.0275rem] text-zembl-forest"
        >
          {header}
        </Text>

        <Text
          intent="body"
          className="mb-8 w-100 text-center  font-sequel text-[1.75rem]  text-h4 font-med-bold  leading-[1.625rem]   text-zembl-ocean"
        >
          {subHeader}
        </Text>
        <Text
          intent="body"
          className="mb-4 w-112 text-center font-sequel text-[1.25rem] text-zembl-ocean"
        >
          Please ensure your password has at least 8 characters, including one special symbol and one
          number.
        </Text>
        <Form
          onSubmit={handleSubmit(handleChangePassword)}
          className="w-120  max-w-form items-center gap-4 rounded-3xl border  border-current bg-zembl-white"
        >
          <div className=" grid  grid-cols-1">
            <div className="mb-3">
              <label
                htmlFor="currentPassword"
                className="mb-1 text-body font-med-bold leading-[1.5rem] tracking-[0.0125rem] text-zembl-forest"
              >
                Current Password
              </label>
              <ControlledInput control={control} name="currentPassword" type="password" placeholder="" />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="newPassword"
                  className=" text-body font-med-bold leading-[1.5rem] tracking-[0.0125rem] text-zembl-forest"
                >
                  New Password
                </label>
                <ControlledInput control={control} name="newPassword" type="password" placeholder="" />
              </div>
              <div>
                <label
                  htmlFor="confirmNewPassword"
                  className="  text-body font-med-bold leading-[1.5rem] tracking-[0.0125rem] text-zembl-forest"
                >
                  Re-enter New Password
                </label>
                <ControlledInput
                  control={control}
                  name="confirmNewPassword"
                  type="password"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="mt-4 w-full">
            <Button intent="green" className="w-full" type="submit">
              Update settings
            </Button>
          </div>
        </Form>
      </div>
      <Modal isShown={isResettingPassword} onClose={setIsResettingPassword}>
        <Form className=" w-110  items-center  rounded-2xl">
          <CheckCircleGreenIcon width="40" className="mb-4" />
          <div className="text-center font-sequel text-[1.75rem] font-med-bold leading-[1.625rem] tracking-[0.0525rem] text-zembl-forest">
            {resetPasswordHeading}
          </div>
          <Text
            intent="body"
            className="mt-4 px-3 text-center font-sequel text-[1.25rem] font-med-thin leading-[1.625rem] tracking-[0.05rem] text-zembl-forest"
          >
            {resetPasswordSubheading}
          </Text>
        </Form>
      </Modal>
    </PageLayout>
  );
};
