import classNames from 'classnames';
import React from 'react';
import { Navbar } from '../navbar/navbar.component';
import cn from 'classnames';

type PageLayoutProps = {
  children: React.ReactNode;
  footerClass?: string;
  className?: string;
};

export const Footer = ({ className }: any) => {
  return (
    <footer className={cn('footer box-border border-t border-black  py-[10px]  ', className)}>
      <div className="  flex items-center justify-between px-5 text-body font-med-thin   xs:px-5 xs:text-1 sm:text-1 lg:px-20 lg:text-1.25">
        <a target="_blank" rel="noopener noreferrer">
          © Zembl 2023
        </a>
        <a>ABN 29138847757</a>
        <a href="https://www.zembl.com.au/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        <a
          href="https://www.zembl.com.au/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms &amp; Conditions
        </a>
      </div>
    </footer>
  );
};

export function PageLayout({
  children,
  footerClass = 'bg-zembl-background',
  className = 'bg-zembl-background',
}: PageLayoutProps) {
  return (
    <div className="flex min-h-screen flex-col">
      <Navbar />
      <main className={`flex-grow ${className}`}>{children}</main>
      <Footer className={footerClass} />
    </div>
  );
}
