import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type FastLink = {
  access_token: string;
  fast_link_url: string;
  fast_link_config_name: string;
  success: boolean;
};

type Store = {
  fastLink?: FastLink;
  setFastLink: (fastLink: any) => void;
  resetFastLink: () => void;
};

const initialState: Store = {
  fastLink: null,
  setFastLink: () => {},
  resetFastLink: () => {},
};

export const useFastLinkStore = create<Store>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setFastLink: (fastLink) => {
          set({ fastLink });
        },
        resetFastLink: () => {
          set((state) => {
            window.localStorage.removeItem('fastlink-store');
            return { fastLink: null };
          });
        },
      }),
      {
        name: 'fastlink-store', // unique name
        getStorage: () => window.localStorage, // (optional) by default the 'localStorage' is used
      }
    )
  )
);
