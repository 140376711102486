import { useOnClickOutside } from './click-outside.component';
import { DASHBOARD_ROUTE, HOME_ROUTE } from 'domain/common/constants';
import classNames from 'classnames';
import { navbar } from 'domain/common/constants/navbar.constants';
import { LogoutIcon, PersonIcon, LinkIcon, LockIcon } from 'domain/assets';
import { useNavigate } from 'react-router-dom';
import { useState, useRef } from 'react';
import { useSignOutQuery } from 'domain/queries';
import { useAuthStore } from 'domain/state';

export const Profile = () => {
  const { profileMenu } = navbar;
  const [isProfileMenuOpen, toggleIsProfileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const setUser = useAuthStore((state) => state.setUser);
  const resetCache = useAuthStore((state) => state.resetCache);
  useOnClickOutside(wrapperRef, () => toggleIsProfileMenuOpen(false));

  const { mutateAsync: signOutRequest } = useSignOutQuery({
    onSuccess: () => {
      setUser(null);
      resetCache();
    },
    onError: () => {
      setUser(null);
      navigate(HOME_ROUTE.HOME);
      resetCache();
    },
  });

  return (
    <div ref={wrapperRef}>
      <button
        className="ml-6 flex h-12 w-12 items-center justify-center rounded-full bg-zembl-violet font-medium text-black "
        onClick={() => {
          toggleIsProfileMenuOpen(!isProfileMenuOpen);
        }}
      >
        JS
      </button>
      <ul
        className={classNames(
          'absolute right-0 top-full z-20 flex flex-col rounded-2xl bg-zembl-white px-5 py-1 text-zembl-forest xs:px-2 sm:px-3 md:px-4',
          { hidden: !isProfileMenuOpen }
        )}
      >
        <li
          onClick={() => {
            navigate(DASHBOARD_ROUTE.CONNECT_ACCOUNT);
          }}
          className="flex cursor-pointer gap-2 p-2 md:gap-1 md:p-1"
        >
          <LockIcon className="icon" />
          <p>{profileMenu[3].label}</p>
        </li>
        <li
          onClick={() => {
            navigate(DASHBOARD_ROUTE.ACCOUNT_SETTINGS);
          }}
          className="flex cursor-pointer gap-2 p-2 md:gap-1 md:p-1"
        >
          <PersonIcon className="icon" />
          <p>{profileMenu[0].label}</p>
        </li>
        <li
          className="flex cursor-pointer gap-2 p-2 md:gap-1 md:p-1"
          onClick={() => {
            navigate(DASHBOARD_ROUTE.CONNECTION_SETTING);
          }}
        >
          <LinkIcon className="icon" />
          <p>{profileMenu[1].label}</p>
        </li>
        <li
          onClick={() => {
            navigate(DASHBOARD_ROUTE.SECURITY_SETTINGS);
          }}
          className="flex cursor-pointer gap-2 p-2 md:gap-1 md:p-1"
        >
          <LockIcon className="icon" />
          <p>{profileMenu[2].label}</p>
        </li>

        <li
          key={'Logout'}
          className="flex cursor-pointer gap-2 p-2 md:gap-1 md:p-1"
          onClick={() => {
            resetCache();
            signOutRequest();
          }}
        >
          <LogoutIcon />
          <p>Logout</p>
        </li>
      </ul>
    </div>
  );
};
