import { DASHBOARD_ROUTE, HOME_ROUTE } from 'domain/common/constants';
import { LogoIcon, PersonIcon, LinkIcon, LockIcon } from 'domain/assets';
export const navbar = {
  logo: {
    src: LogoIcon,
    alt: 'Zembl Logo',
  },
  home: [
    {
      href: HOME_ROUTE.HOME,
      children: 'Home',
    },
    {
      href: HOME_ROUTE.HOW_IT_WORLS,
      children: 'How it works',
    },
    {
      href: HOME_ROUTE.CONTACT,
      children: 'Contact',
    },
  ],
  dashboard: [
    {
      href: DASHBOARD_ROUTE.DASHBOARD,
      children: 'Dashboard',
    },
    {
      href: DASHBOARD_ROUTE.OFFERS,
      children: 'Offers',
    },
    {
      href: DASHBOARD_ROUTE.CONTACT,
      children: 'Contact',
    },
  ],
  profileMenu: [
    {
      icon: {
        src: PersonIcon,
        alt: 'My Account',
      },
      label: 'My Account',
    },
    {
      icon: {
        src: LinkIcon,
        alt: 'Connection Setting',
      },
      label: 'Connection Setting',
    },

    {
      icon: {
        src: LockIcon,
        alt: 'Security',
      },
      label: 'Security',
    },
    {
      icon: {
        src: LinkIcon,
        alt: 'Connect Account',
      },
      label: 'Connect Account',
    },
  ],
};
