export function formatCurrency(value: any) {
  const options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  };

  return new Intl.NumberFormat('en-US', options)?.format(value);
}

export const extractBankName = (str: any) => {
  return str?.split(' - ')[0];
};
