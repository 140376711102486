import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type FinancialAccount = {
  uuid: string;
  account_name: string;
  account_number: string;
  account_balance: number;
  currency: string;
  account_status: string;
  created_at: string;
  updated_at: string;
};

type FinancialAccountsStore = {
  financialAccounts: FinancialAccount[];
  setFinancialAccounts: (accounts: FinancialAccount[]) => void;
};

export const useFinancialAccountsStore = create<FinancialAccountsStore>()(
  devtools((set) => ({
    financialAccounts: [],
    setFinancialAccounts: (accounts) => {
      set({ financialAccounts: accounts });
    },
  }))
);
