import { InputHTMLAttributes } from 'react';
import classNames from 'classnames';

export type InputProps = {
  required?: boolean;
  placeholder: string;
} & InputHTMLAttributes<HTMLInputElement>;
export function Input({ required, placeholder, className, ...props }: InputProps) {
  const _placeholder = required ? `* ${placeholder}` : placeholder;

  return (
    <input
      className={classNames(
        'placeholder:black  rounded-3xl border border-black px-5 py-3 text-zembl-forest ',
        className
      )}
      {...props}
      placeholder={_placeholder}
    />
  );
}
