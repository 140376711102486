import classNames from 'classnames';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Text } from '../text/text.component';
import { Textarea, TextareaProps } from './textarea.component';

type ControlledTextareaProps = {
  name: string;
  control: Control<any, any>;
} & TextareaProps;
export function ControlledTextarea({ name, control, className, ...props }: ControlledTextareaProps) {
  const {
    field: { ref, ...fields },
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div className={classNames(className, 'flex flex-col bg-white')}>
      <Textarea className="rounded-xl" {...fields} {...props} />
      {error && <Text intent="error">{error.message}</Text>}
    </div>
  );
}
