import { useEffect, useState } from 'react';
import { Button, Modal, PageLayout } from 'domain/common/components';
import { TrashIcon, ArrowLeftIcon } from 'domain/assets';
import {
  useGetFinancialAccountsQuery,
  useGetSpecificFinancialAccountQuery,
  useUpdateSpecificFinancialAccountMutation,
  useRefreshSpecificFinancialProviderAccountMutation,
} from 'domain/queries/account.query';
import { useAuthStore } from 'domain/state';
import {
  useDeleteSpecificFinancialProviderAccountMutation,
  useGetFinancialProviderAccountsQuery,
  useGetSpecificFinancialProviderAccountQuery,
} from 'domain/queries/financial.providers.query';
import { useFinancialProviderAccountsStore } from 'domain/state/financial-provider-account.store';
import { useFinancialAccountsStore } from 'domain/state/financial-accounts.store';
import { UpdateAccount } from './update-account.modal';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTE } from 'domain/common/constants';
import { formatCurrency, extractBankName } from 'domain/utils';

function useFinancialProviderAccounts(token: string, uuid?: string) {
  const financialProviderAccountsQuery = useGetFinancialProviderAccountsQuery(token);
  const specificFinancialProviderAccountQuery = useGetSpecificFinancialProviderAccountQuery(token, uuid);
  const deleteSpecificFinancialProviderAccountMutation =
    useDeleteSpecificFinancialProviderAccountMutation();
  const refreshSpecificFinancialProviderAccount = useRefreshSpecificFinancialProviderAccountMutation();
  return {
    financialProviderAccountsQuery,
    specificFinancialProviderAccountQuery,
    deleteSpecificFinancialProviderAccountMutation,
    refreshSpecificFinancialProviderAccount,
  };
}

function useFinancialAccounts(token: string, uuid?: string) {
  const financialAccountsQuery = useGetFinancialAccountsQuery(token);
  const specificFinancialAccountQuery = useGetSpecificFinancialAccountQuery(token, uuid);
  const updateSpecificFinancialAccountMutation = useUpdateSpecificFinancialAccountMutation();

  return {
    financialAccountsQuery,
    specificFinancialAccountQuery,
    updateSpecificFinancialAccountMutation,
  };
}

function CenteredContainer({ children }: any) {
  return (
    <div className="flex content-center items-center justify-center px-12 pb-12 pt-16">
      <div className="  w-140  gap-2 rounded-[30px] border border-solid border-[#19292c] bg-white">
        {children}
      </div>
    </div>
  );
}

const ListItem = ({ item, isFirst, onAccountClick, onDelete }: any) => (
  <div
    className={`flex cursor-pointer items-center space-x-4 p-[0.625rem] ${
      !isFirst && 'border-zembl-gray-light border-t pt-4'
    }`}
    onClick={() => onAccountClick(item)}
  >
    <button
      id="account-delete"
      className="focus:outline-none"
      onClick={(e) => {
        e.stopPropagation();
        onDelete(item.uuid);
      }}
    >
      <TrashIcon className="mr-px h-6 w-6 shrink-0" />
    </button>
    <img src={item.logo} className="mr-px w-16 shrink-0 self-start" />
    <div className="text-xl font-medium leading-[1.625rem] tracking-[0.8] text-zembl-forest">
      {item.name}
    </div>
  </div>
);

const AccountsList = ({ onAccountClick, onDelete }: any) => {
  const { financialProviderAccounts } = useFinancialProviderAccountsStore();
  const navigate = useNavigate();

  return (
    <div className="mx-[3.12rem] space-y-4 py-8">
      {financialProviderAccounts &&
        financialProviderAccounts.map((item: any, index: any) => {
          return (
            <ListItem
              key={`account-provider-${index}`}
              item={item}
              isFirst={index === 0}
              onAccountClick={onAccountClick}
              onDelete={onDelete}
            />
          );
        })}
      <div className="my-3 mb-[3.12rem] flex content-center items-center justify-center">
        <Button
          intent="green"
          type="submit"
          onClick={() => {
            navigate(DASHBOARD_ROUTE.CONNECT_ACCOUNT);
          }}
          className="flex h-12 w-72 flex-col items-center justify-center overflow-hidden rounded border border-solid border-[#fefefc] p-5 px-9 py-2 pl-5"
        >
          Add Account
        </Button>
      </div>
    </div>
  );
};

const SubAccountItem = ({
  item,
  index,
  onDelete,
}: {
  item: any;
  index: number;
  onDelete: (uuid: string) => void;
}) => {
  return (
    <div
      key={index}
      className={`flex cursor-pointer items-start justify-between p-[0.625rem] ${
        index !== 0 ? 'border-zembl-gray-light border-t pt-4' : ''
      }`}
    >
      <div className="flex items-center space-x-4">
        <button
          id="sub-account-delete"
          className="mt-1 focus:outline-none"
          onClick={() => onDelete(item?.uuid)}
        >
          <TrashIcon className="h-6 w-6" />
        </button>
        <div>
          <div className="text-xl font-medium leading-[1.625rem] tracking-[0.8] text-zembl-forest">
            {extractBankName(item.account_name)}
          </div>
          <div className="text-base text-gray-500">{item.account_number}</div>
        </div>
      </div>
      <span className="text-xl text-zembl-forest">{`${formatCurrency(item?.account_balance)}`}</span>
    </div>
  );
};

const SubAccountHeader = ({ onBackClick, account }: any) => {
  console.log('account', account);
  return (
    <div className="mb-6 flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <button className="focus:outline-none" onClick={onBackClick}>
          <ArrowLeftIcon className="h-6 w-6 text-zembl-forest" />
        </button>
        <img src={account?.logo} className="h-12 w-12 shrink-0 text-zembl-forest" />
      </div>
      <h1 className="text-[1.25rem] text-zembl-forest">{account?.name}</h1>
    </div>
  );
};

const BankConnectionStatus = () => {
  return (
    <div className=" flex h-100 w-120 flex-col items-center justify-center rounded-xl bg-white  text-center text-zembl-forest">
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="&#38; I / Action  / lock">
          <path
            id="Vector"
            d="M37.5002 16.6667H35.4168V12.5C35.4168 6.75004 30.7502 2.08337 25.0002 2.08337C19.2502 2.08337 14.5835 6.75004 14.5835 12.5V16.6667H12.5002C10.2085 16.6667 8.3335 18.5417 8.3335 20.8334V41.6667C8.3335 43.9584 10.2085 45.8334 12.5002 45.8334H37.5002C39.7918 45.8334 41.6668 43.9584 41.6668 41.6667V20.8334C41.6668 18.5417 39.7918 16.6667 37.5002 16.6667ZM25.0002 35.4167C22.7085 35.4167 20.8335 33.5417 20.8335 31.25C20.8335 28.9584 22.7085 27.0834 25.0002 27.0834C27.2918 27.0834 29.1668 28.9584 29.1668 31.25C29.1668 33.5417 27.2918 35.4167 25.0002 35.4167ZM18.7502 16.6667V12.5C18.7502 9.04171 21.5418 6.25004 25.0002 6.25004C28.4585 6.25004 31.2502 9.04171 31.2502 12.5V16.6667H18.7502Z"
            fill="#19292C"
          />
        </g>
      </svg>

      <div className="relative py-6">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.9s_linear_infinite]"
          role="status"
        >
          <span className="sr-only absolute">Loading...</span>
        </div>
        <p className="font-sequel-sans px-20 py-6 text-center text-lg font-[158] not-italic leading-[1.625rem] tracking-[0.05rem]">
          Connecting to bank. Loading utility transactions. This may take a couple of minutes.
        </p>
      </div>
    </div>
  );
};

const SubAccountsList = ({ onBackClick, onDelete, onRefresh, selectedAccount }: any) => {
  const { financialAccounts } = useFinancialAccountsStore();
  const [showUpdateAccountModal, setShowUpdateAccountModal] = useState(false);
  const [showRefreshAccountModal, setShowRefreshAccountModal] = useState(false);

  return (
    <>
      <div className="mx-[3.12rem] space-y-4 py-8">
        <SubAccountHeader onBackClick={onBackClick} account={selectedAccount} />
        {financialAccounts &&
          financialAccounts.map((item, index) => (
            <SubAccountItem key={index} item={item} index={index} onDelete={onDelete} />
          ))}
      </div>
      <div className="r  flex px-5 pb-10 text-center">
        <Button
          intent="green"
          type="submit"
          className="mx-3 h-full w-full"
          onClick={() => setShowUpdateAccountModal(true)}
        >
          Update Bank Login
        </Button>
        <Button
          intent="tertiary"
          type="submit"
          className="mx-3 h-full w-full"
          // onClick={onRefresh}
          onClick={() => {
            setShowRefreshAccountModal(true);
          }}
        >
          Refresh Feed
        </Button>
      </div>
      <Modal isShown={showUpdateAccountModal} onClose={() => setShowUpdateAccountModal(false)}>
        <UpdateAccount selectedUuid={selectedAccount?.uuid} />
      </Modal>
      <Modal isShown={showRefreshAccountModal} onClose={() => setShowRefreshAccountModal(false)}>
        <BankConnectionStatus />
      </Modal>
    </>
  );
};

const AccountsListContainer = () => {
  const [showSubAccounts, setShowSubAccounts] = useState(false);
  const contactDetails = useAuthStore((state) => state.contactDetails);
  const authToken = useAuthStore((state) => state.user?.auth_token);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);

  const { setFinancialProviderAccounts } = useFinancialProviderAccountsStore();

  const { setFinancialAccounts } = useFinancialAccountsStore();

  const {
    financialAccountsQuery,
    specificFinancialAccountQuery,
    updateSpecificFinancialAccountMutation,
  } = useFinancialAccounts(authToken, contactDetails?.uuid);

  const {
    financialProviderAccountsQuery,
    specificFinancialProviderAccountQuery,
    deleteSpecificFinancialProviderAccountMutation,
    refreshSpecificFinancialProviderAccount,
  } = useFinancialProviderAccounts(authToken);

  const handleOnload = async () => {
    try {
      const response = await financialProviderAccountsQuery.refetch();
      if (response?.isSuccess && response?.data?.data.success) {
        setFinancialProviderAccounts(response?.data?.data?.data);
      }
    } catch (error) {
      console.error('Failed to refetch financial provider accounts:', error);
    }
  };
  useEffect(() => {
    handleOnload();
  }, []);

  const handleAccountClick = async (item: string) => {
    try {
      // Use uuid here in your refetch or other query
      const response = await financialAccountsQuery.refetch();
      if (response?.isSuccess && response?.data?.data.success) {
        setFinancialAccounts(response?.data?.data?.data);
        setSelectedAccount(item);
        setShowSubAccounts(true);
      }
    } catch (error) {
      console.error('Failed to refetch specific financial provider account:', error);
    }
  };

  const handleDelete = async (uuid: string) => {
    try {
      await deleteSpecificFinancialProviderAccountMutation.mutateAsync({
        token: authToken,
        uuid: uuid,
      });
      handleOnload();
    } catch (error) {
      console.error('Failed to delete account:', error);
    }
  };

  const handleRefresh = async (uuid: string) => {
    try {
      await refreshSpecificFinancialProviderAccount.mutateAsync({
        token: authToken,
        uuid: uuid,
      });
    } catch (error) {
      console.error('Failed to refresh account feed:', error);
    }
  };

  useEffect(() => {
    handleOnload();
  }, []);

  return (
    <CenteredContainer>
      {!showSubAccounts ? (
        <AccountsList
          onAccountClick={(item: any) => {
            console.log('item', item);
            handleAccountClick(item);
          }}
          onDelete={handleDelete}
        />
      ) : (
        <SubAccountsList
          selectedAccount={selectedAccount}
          onBackClick={() => {
            setShowSubAccounts(false);
          }}
          onDelete={(uuid: any) => {
            handleDelete(uuid);
          }}
          onRefresh={() => {
            handleRefresh(selectedAccount);
          }}
        />
      )}
    </CenteredContainer>
  );
};

export const ConnectionSetting = () => (
  <PageLayout className="bg-zembl-background" footerClass="bg-zembl-background">
    <div className="my-zembl-dark my-10 text-5xl leading-[50px] tracking-[0.44] text-zembl-forest">
      Connection Setting
    </div>
    <div className="font-sequel-sans text-center text-2xl  text-zembl-forest">
      You can add and remove account connections at any time.
    </div>
    <AccountsListContainer />
  </PageLayout>
);
