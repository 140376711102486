import { Option } from 'domain/common/models';

export const industries = [
  'business',
  'warehousing',
  'health',
  'agriculture',
  'hospitality',
  'retail',
  'trade',
  'arts-and-recreation',
] as const;
type Industry = (typeof industries)[number];

export const noOfEmployees = ['1', '5', '25'] as const;
type NoOfEmployees = (typeof noOfEmployees)[number];

export const avgMonthlyTurnover = ['0', '5', '25'] as const;
type AvgMonthlyTurnOver = (typeof avgMonthlyTurnover)[number];

const createAccountFormFields = {
  firstName: {
    name: 'firstName',
    required: true,
    label: 'First Name',
    error: 'Please complete all mandatory fields marked with *',
  },
  surname: {
    name: 'surname',
    required: true,
    label: 'Surname',
    error: 'Please complete all mandatory fields marked with *',
  },
  email: {
    name: 'email',
    required: true,
    label: 'Email',
    error: 'Please complete all mandatory fields marked with *',
  },
  phone: {
    name: 'phone',
    required: true,
    label: 'Phone',
    error: 'The phone must be 10 digits and start with one of 02|03|04|07|08 with no spaces',
  },
  abn: {
    className: 'col-span-2',
    name: 'abn',
    required: true,
    label: 'ABN or Business Name',
    error: 'Please complete all mandatory fields marked with *',
  },
  password: {
    type: 'password',
    name: 'password',
    required: true,
    label: 'Choose password',
    error: 'Please complete all mandatory fields marked with *',
  },
  confirmPassword: {
    type: 'password',
    name: 'confirmPassword',
    required: true,
    label: 'Re-enter Password',
    error: 'Please complete all mandatory fields marked with *',
  },
  tnc: {
    className: 'col-span-2',
    name: 'tnc',
    required: true,
    message: 'I have read and agree to the ',
    link: {
      text: 'Terms and Conditions',
      url: 'https://www.zembl.com.au/terms-and-conditions',
    },
    error: 'Please complete all mandatory fields marked with *',
  },
};

const selectIndustryOptions: Option<Industry>[] = [
  { name: 'Business Services', value: 'business' },
  { name: 'Warehousing', value: 'warehousing' },
  { name: 'Health Services', value: 'health' },
  { name: 'Agriculture', value: 'agriculture' },
  { name: 'Hospitality', value: 'hospitality' },
  { name: 'Retail', value: 'retail' },
  { name: 'Trade', value: 'trade' },
  { name: 'Arts and Recreation Services', value: 'arts-and-recreation' },
];
const noOfEmployeesOptions: Option<NoOfEmployees>[] = [
  { name: '1-5', value: '1' },
  { name: '5-25', value: '5' },
  { name: '25+', value: '25' },
];

const avgMonthlyTurnoverOptions: Option<AvgMonthlyTurnOver>[] = [
  { name: '$0k-$5k', value: '0' },
  { name: '$5k-$25k', value: '5' },
  { name: '$25k+', value: '25' },
];

const tellUsAboutYourBusinessFields = {
  selectIndustry: {
    name: 'selectIndustry',
    required: true,
    placeholder: 'Select Industry',
    error: 'Please complete all mandatory fields marked with *',
    options: selectIndustryOptions,
  },
  noOfEmployees: {
    name: 'noOfEmployees',
    placeholder: 'No. of Employees',
    error: 'Please complete all mandatory fields marked with *',
    options: noOfEmployeesOptions,
  },
  avgMonthlyTurnover: {
    name: 'avgMontlyTurnover',
    placeholder: 'What is your avg monthly turnover?',
    error: 'Please complete all mandatory fields marked with *',
    options: avgMonthlyTurnoverOptions,
  },
};

export const signUpForm = {
  createAccount: {
    heading: 'Create Account',
    fields: createAccountFormFields,
    button: 'Create Account',
  },
  tellUsAboutYourBusiness: {
    heading: 'Tell us a bit about your business',
    subheading: 'This information helps us give you insights and customise the experience',
    fields: tellUsAboutYourBusinessFields,
    button: 'Next',
  },
  welcome: {
    heading: 'Welcome!',
    subheading: "On average we've saved businesses like yours an estimated $3,500* per year.",
    button: 'Go To Dashboard',
  },
};
