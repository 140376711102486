import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

export type ButtonIntent =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'accent-dark'
  | 'green'
  | 'billUpload'
  | 'bill-dark';

export type ButtonProps = {
  children: any;
  intent?: ButtonIntent;
} & ButtonHTMLAttributes<HTMLButtonElement>;
interface ButtonClassMap {
  [intent: string]: string;
}

const BUTTON_STYLES_MAP: ButtonClassMap = {
  primary: 'bg-zembl-teal text-white',
  secondary: 'bg-zembl-ocean text-white',
  tertiary: 'bg-zembl-forest text-white',
  accent: 'bg-zembl-violet text-white',
  'accent-dark': 'bg-zembl-violet text-zembl-forest',
  green: 'bg-zembl-green',
  billUpload:
    'bg-zembl-white h-12 w-5/6 flex justify-center items-center text-zembl-green text-md font-bold',
  'bill-dark': 'bg-black text-white w-5/6 justify-center items-center font-bold',
};

export function Button({ children, intent = 'primary', className, ...props }: ButtonProps) {
  const baseClasses =
    'rounded px-2 py-1 font-sequel xs:px-4 xs:py-1 sm:px-6 sm:py-3 text-black text-xl font-med-bold';
  const intentClass = BUTTON_STYLES_MAP[intent] || '';

  const defaultClasses = classNames(className, baseClasses, intentClass);

  return (
    <button {...props} className={defaultClasses}>
      {children}
    </button>
  );
}
