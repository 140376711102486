import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type FinancialProviderAccountsStore = {
  financialProviderAccounts: any;
  setFinancialProviderAccounts: (accounts: any) => void;
  success: any;
  setSuccess: (value: any) => void;
};

export const useFinancialProviderAccountsStore = create<FinancialProviderAccountsStore>()(
  devtools((set) => ({
    financialProviderAccounts: [],
    setFinancialProviderAccounts: (accounts) => {
      set({ financialProviderAccounts: accounts });
    },
    success: false,
    setSuccess: (value) => {
      set({ success: value });
    },
  }))
);
