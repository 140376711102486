import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useToggle } from 'domain/hooks';
import { checkCircleGreen } from 'domain/assets';

import {
  PageLayout,
  Form,
  Text,
  ControlledInput,
  ControlledSelect,
  Button,
  Modal,
} from 'domain/common/components';

const noOfEmployeesOptions = ['1-5', '5-25', '25+'];
const avgMonthlyTurnoverOptions = ['$0k-$5k', '$5k-$25k', '$25k+'];
const industryOptions = [
  'Business Services',
  'Warehousing',
  'Health Services',
  'Agriculture',
  'Hospitality',
  'Retail',
  'Trade',
  'Arts and Recreation Services',
].sort();

const accountSettingsFormSchema = z.object({
  marketingConsent: z.boolean(),
  preferredPhoneNumber: z.string(),
});

type AccountSettingsFormValue = {
  firstName: string;
  surname: string;
  email: string;
  phone: string;
  preferredPhoneNumber: string;
  noOfEmployees: string;
  avgMonthlyTurnover: string;
  industry: string;
  marketingConsent: boolean;
};

function useAccountSettings() {
  const { control, handleSubmit } = useForm<AccountSettingsFormValue>({
    defaultValues: {
      firstName: 'John',
      surname: 'Smith',
      email: 'john@email.com',
      phone: '0412 345 678',
      preferredPhoneNumber: '',
      noOfEmployees: '1-5',
      avgMonthlyTurnover: '$0k-$5k',
      industry: 'Business Services',
      marketingConsent: false,
    },
    resolver: zodResolver(accountSettingsFormSchema),
    mode: 'onBlur',
  });

  const handleUpdateSettings = handleSubmit(async (data: any) => {});

  return { control, handleUpdateSettings };
}

const messageConfirmation = {
  icon: { src: checkCircleGreen, alt: 'Check circle' },
  changeSettingHeading: 'Settings Updated',
  changeSettingSubheading: 'Your details have been saved.',
};

export const AccountSettings = () => {
  const [isSettingsChanged, setIsSettingsChanged] = useToggle(false);
  const { icon, changeSettingHeading, changeSettingSubheading } = messageConfirmation;
  const header = 'Account Settings';
  const subHeader =
    "\u200bHere's your current profile. Update your business details below, or contact us to update your personal details.";

  const accountSettingsForm = useAccountSettings();

  return (
    <PageLayout className=" bg-zembl-background" footerClass=" bg-zembl-background">
      <div className="mb-14 mt-14  ">
        <div className="flex flex-col justify-center text-center">
          <div className="my-zembl-dark    text-zembl-forest">{header}</div>
          <div className="flex w-full justify-center text-center">
            <div className=" mb-8 mt-4 w-112   text-[28px] leading-[26px] text-zembl-forest">
              {subHeader}
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <Form
            onSubmit={accountSettingsForm.handleUpdateSettings}
            className="w-full max-w-form rounded-3xl border border-current"
          >
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="mb-2.5">
                  <label htmlFor="phone" className="font-semibold text-zembl-forest">
                    Phone
                  </label>
                </div>
                <ControlledInput
                  required={false}
                  control={accountSettingsForm.control}
                  name="phone"
                  placeholder="Phone"
                  className=" text-black"
                  readOnly
                />
              </div>
              <div>
                <div className="mb-2.5">
                  <label htmlFor="email" className="font-semibold text-zembl-forest">
                    Email
                  </label>
                </div>
                <ControlledInput
                  control={accountSettingsForm.control}
                  name="email"
                  placeholder="Email"
                  className=" text-black"
                  readOnly
                />
              </div>
              <div>
                <div className="mb-2.5">
                  <label htmlFor="firstName" className="mb-9 text-lg font-semibold text-zembl-forest ">
                    First Name
                  </label>
                </div>
                <ControlledInput
                  control={accountSettingsForm.control}
                  name="firstName"
                  className=" text-black"
                  placeholder="First Name"
                  readOnly
                />
              </div>
              <div>
                <div className="mb-2.5">
                  <label htmlFor="surname" className=" font-semibold text-zembl-forest">
                    Surname
                  </label>
                </div>
                <ControlledInput
                  control={accountSettingsForm.control}
                  name="surname"
                  placeholder="Surname"
                  className=" text-black"
                  readOnly
                />
              </div>
              <div>
                <div className="mb-2.5">
                  <label htmlFor="noOfEmployees" className="font-semibold text-zembl-forest">
                    No. of Employees
                  </label>
                </div>
                <ControlledSelect
                  control={accountSettingsForm.control}
                  name="noOfEmployees"
                  placeholder="No. of Employees"
                  className=" w-full text-black"
                  options={noOfEmployeesOptions.map((value) => ({ name: value, value }))}
                />
              </div>
              <div>
                <div className="mb-2.5">
                  <label htmlFor="avgMonthlyTurnover" className="font-semibold text-zembl-forest">
                    Avg. Monthly Turnover
                  </label>
                </div>
                <ControlledSelect
                  control={accountSettingsForm.control}
                  className=" w-full"
                  name="avgMonthlyTurnover"
                  placeholder="Avg. Monthly Turnover"
                  options={avgMonthlyTurnoverOptions.map((value) => ({ name: value, value }))}
                />
              </div>
              <div>
                <div className="mb-2.5">
                  <label htmlFor="industry" className=" font-semibold text-zembl-forest">
                    Industry
                  </label>
                </div>
                <ControlledSelect
                  control={accountSettingsForm.control}
                  className=" w-full text-black"
                  name=" industry"
                  placeholder="Select Industry"
                  options={industryOptions.map((value) => ({ name: value, value }))}
                />
              </div>
              <div>
                <div className="mb-2.5">
                  <label htmlFor="preferredPhoneNumber" className="font-semibold text-zembl-forest">
                    Preferred Phone Number
                  </label>
                </div>
                <ControlledInput
                  required={false}
                  control={accountSettingsForm.control}
                  name="preferredPhoneNumber"
                  placeholder=""
                  className=" text-black"
                />
              </div>
            </div>

            {/* <div className="mt-4 ">
            <ControlledCheckbox control={accountSettingsForm.control} name="marketingConsent">
              I consent to receiving marketing communications
            </ControlledCheckbox>
          </div> */}
            <div className="mt-4 w-full">
              <Button intent="green" className="w-full text-xl" type="submit">
                Update settings
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <Modal isShown={isSettingsChanged} onClose={() => setIsSettingsChanged()}>
        <Form className=" w-110  items-center rounded-3xl ">
          <img {...icon} className="mb-4" />
          <div className="mb-4 text-2xl  text-black">{changeSettingHeading}</div>
          <Text intent="body" className="text-center">
            {changeSettingSubheading}
          </Text>
        </Form>
      </Modal>
    </PageLayout>
  );
};
