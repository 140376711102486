import {
  LeafIcon,
  MoneyIcon,
  SupportIcon,
  solarEnergy,
  solarContentImg,
  monetizationOn,
  businessLoan,
  contacts,
  phoneInTalk,
  CheckCircleGreenIcon,
  sentimentVerySatisfied,
  DatabaseIcon,
  AvatarIcon,
  WhiteTickIcon,
} from 'domain/assets';
import { DASHBOARD_OFFER_ROUTE } from 'domain/common/constants';
import { OfferCardProps } from '../../pages/offers/components/offer-card.component';

const selectIndustryOptions = [
  { name: 'Business Services', value: 'business' },
  { name: 'Warehousing', value: 'warehousing' },
  { name: 'Health Services', value: 'health' },
  { name: 'Agriculture', value: 'agriculture' },
  { name: 'Hospitality', value: 'hospitality' },
  { name: 'Retail', value: 'retail' },
  { name: 'Trade', value: 'trade' },
  { name: 'Arts and Recreation Services', value: 'arts-and-recreation' },
];
export const solarFields = {
  selectIndustry: {
    name: 'selectIndustry',
    required: true,
    placeholder: 'Select Industry',
    error: 'Please complete all mandatory fields marked with *',
    options: selectIndustryOptions,
  },
};

const cards: OfferCardProps[] = [
  {
    thumbnail: {
      src: solarEnergy,
      alt: 'Solar Energy Thumbnail',
    },
    title: 'Save On Energy Bills With Solar',
    description:
      'A commercial solar system tailored to your business needs helps reduce your daytime electricity costs and your bottom line.',
    cta: {
      label: 'Learn more',
      redirectTo: DASHBOARD_OFFER_ROUTE.SOLAR_ENERGY,
    },
  },
  {
    thumbnail: {
      src: businessLoan,
      alt: 'Business Loan Thumbnail',
    },
    title: 'Get Simple and Easy Asset Finance',
    description:
      'Our business lending experts help you find a business loan to meet your needs hassle-free.',
    cta: {
      label: 'Learn more',
      redirectTo: DASHBOARD_OFFER_ROUTE.BUSINESS_LOAN,
    },
  },
];

export const offers = {
  heading: 'Offers',
  subheading: 'Exclusive To Zembl Customers',
  cards,
};

export const solar = {
  cta: 'Make an enquiry',
  hero: {
    title: 'Solar',
    description: 'Zembl  helps your business save on energy consumption and costs',
    tooltips: {
      line1: 'You could save an estimated',
      line2: '$2,000',
      line3: 'per year by switching to Solar energy',
    },
  },
  banner: {
    line1:
      'Our Solar Energy Experts are always on-hand to help deliver long-term cost saving solutions and improve your business’s green credentials.',
  },
  content: {
    img: {
      src: solarContentImg,
      alt: 'Solar Content Image',
    },
    title: 'Why go solar?',
    description:
      'Going solar can help your business in many ways. First, and most importantly, you could boost your bottom line with savings on electricity and operating costs. Promoting your solar energy credentials may also assist your appeal to customers and suppliers – those looking to support businesses committed to sustainable energy practices. It’s also possible that you’ll be eligible for government rebates. No matter what industry you work in, you can reap benefits from going solar.',
    cards: [
      {
        Icon: MoneyIcon,
        title: 'Quality solar solutions',
        description:
          'Designs and installations are approved by the Clean Energy Council regulator. Performance guarantee honoured through 24-hour monitoring. Quality design with top-tier components. Dedicated after-sales service for maximum system performance.',
      },
      {
        Icon: SupportIcon,
        title: 'Get a better solar tariff',
        description:
          'We partner with leading Australian retailers to secure a competitive solar plan for your business, handling all the legwork and paperwork. It only takes minutes to check and compare. Plus, we offer battery options with solar purchase.',
      },

      {
        Icon: LeafIcon,
        title: 'Cashflow positive from day 1',
        description:
          "We offer a range of flexible payment options and provide advice on government rebates and incentives. Choose a 'zero upfront cost option', financing the system and using savings from day one to pay for your solar system.",
      },
    ],
  },
};

export const business = {
  cta: 'Make an enquiry',
  hero: {
    title: 'Asset Finance',
    description: [
      {
        Icon: CheckCircleGreenIcon,
        content: 'Tailored options from 40+ lenders',
      },
      {
        Icon: CheckCircleGreenIcon,
        content: 'Dedicated Business Lending Expert',
      },
      {
        Icon: CheckCircleGreenIcon,
        content: 'No hidden fees or charges',
      },
    ],
    tooltips: {
      icon: { src: monetizationOn, alt: 'Business Loan Icon' },
      line1: '70% of businesses will be cashflow negative at least once a year. ',
    },
  },
  banner: {
    line1:
      "If you're a business looking to finance vehicles, equipment, or even commercial operations, we have the perfect asset finance solution for you.",
    line2:
      'We have access to over 40 of Australia’s leading lenders to help find the right finance for your business. Save time by letting us do all the legwork for you.',
  },
  content: {
    title: 'Compare business finance today!',
    description:
      'Join thousands of Aussie businesses who have compared business finance with Zembl to find the right solution for their business needs.',
    optionsHeader: '2 easy ways to get in touch today:',
    option1: `Option 1:  Take a couple of minutes to complete our form.`,
    option1Line2: `You’ll receive a phone call from a Business Lending Expert who will help you with your business finance needs.`,

    option2:
      'Option 2: Simply call us on 1300 957 721 and speak with a Business Lending Expert at Zembl between the hours of 9:00 - 5:00pm, Monday - Friday.',
    cards: [
      {
        Icon: DatabaseIcon,
        title: 'Easy & Simple',
        description:
          'We’ll do all the legwork and get your application submitted and leverage our market position with lenders to advocate for you.',
      },
      {
        Icon: AvatarIcon,
        title: '100% Australian',
        description:
          'Our Sydney based brokers are experts in helping you find a business loan to meet your business needs.',
      },
      {
        Icon: WhiteTickIcon,
        title: 'Competitive Rates',
        description:
          'We compare over 40 of Australia’s leading lenders to help find the right loan for your business.',
      },
    ],
  },
};
