import { SelectHTMLAttributes } from 'react';
import classNames from 'classnames';
import { Option } from 'domain/common/models';

export type SelectProps = {
  required?: boolean;
  label?: string;
  labelClassName?: string;
  options: Option[];
} & SelectHTMLAttributes<HTMLSelectElement>;
export function Select({
  required,
  disabled,
  label,
  placeholder,
  className,
  labelClassName,
  options,
  ...props
}: SelectProps) {
  const _placeholder = required ? `* ${placeholder}` : placeholder;

  return (
    <label
      className={classNames(labelClassName, 'flex items-center gap-5 rounded-3xl', {
        'text-zembl-ocean': disabled,
      })}
    >
      {label}
      <select
        {...props}
        className={classNames(
          'rounded-3xl ',
          {
            'text-zembl-ocean': disabled,
          },
          className
        )}
        disabled={disabled}
      >
        <option value="0" className="rounded-2xl">
          {_placeholder}
        </option>
        {options.map(({ name, value }, index) => (
          <option key={value + index} value={value}>
            {name}
          </option>
        ))}
      </select>
    </label>
  );
}
