import { useEffect, useState } from 'react';
import { PageLayout, ErrorScreen } from 'domain/common/components';
import { useFastLinkStore } from 'domain/state';
import { ConnectionSuccess } from './connection-sucess.component';
import { useNavigate } from 'react-router-dom';
// If 'handleLogout' is not being used in the ConnectAccount component, we might not need to import it.
// Otherwise, ensure it's imported.
// import { handleLogout } from 'domain/utils';

// Constants
const SCRIPT_SRC = 'https://cdn.yodlee.com/fastlink/v4/initialize.js';
const SCRIPT_DELAY = 500; // in milliseconds
const CONFIG_NAME = 'Dev_Configuration';
const FAST_LANE_ERROR =
  'FastLink already in use, multiple instances of fastLink may not work as expected.';

const addFastLinkScript = (onLoadCallback: () => void): HTMLScriptElement => {
  const script = document.createElement('script');
  script.src = SCRIPT_SRC;
  script.type = 'text/javascript';
  script.onload = onLoadCallback;
  document.body.appendChild(script);
  return script;
};

const closeCurrentConnection = () => {
  if (window?.fastlink?.isConnected) {
    window?.fastlink?.close();
  }
};

export const ConnectAccount = () => {
  const { fastLink } = useFastLinkStore();
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const {
    access_token: accessToken,
    fast_link_url: fastLinkURL,
    fast_link_config_name: configName,
  } = fastLink;

  const handleFastLinkConfiguration = () => {
    if (window.fastlink && !window.fastlink.isConnected) {
      window.fastlink.open(
        {
          fastLinkURL,
          accessToken: `Bearer ${accessToken}`,
          forceIframe: true,
          params: {
            configName: CONFIG_NAME,
          },
          onSuccess: (data: any) => {
            console.info('onSuccess method called: => ', data);
          },
          onError: (data: any) => {
            console.info('onError method called: => ', data);
            setErrorMessage(data?.message);
            // Uncomment the following lines if you want the provided functionality
            // if (data?.message !== FAST_LANE_ERROR) {
            //   handleLogout();
            // }
          },
          onClose: (data: any) => {
            console.log('onExit method called: => ', data);
            setIsSuccess(true);
          },
          onEvent: (obj: any) => {
            if (obj?.fnToCall === 'renewClientSession') {
              setErrorMessage(null);
              setIsSuccess(false);
            }
            console.log('onEvent method called: => ', obj);
            console.log('provider url=>');
          },
        },
        configName
      );
    }
  };
  useEffect(() => {
    const script = addFastLinkScript(() => {
      setTimeout(handleFastLinkConfiguration, SCRIPT_DELAY);
    });

    return () => {
      document.body.removeChild(script);
      closeCurrentConnection();
    };
  }, [accessToken, fastLinkURL, configName]);

  return (
    <PageLayout className="bg-zembl-background" footerClass="bg-zembl-background">
      <div className="my-zembl-dark my-10 text-5xl leading-[50px] tracking-[0.44] text-zembl-forest">
        Add Account
      </div>
      <div className="flex h-full w-full content-center items-center justify-center ">
        {isSuccess && (
          <ConnectionSuccess
            message="Done! Do you wish to add another account? You can also do this at any time under your"
            highlightedText="Connection Settings"
            buttonText="Connect Another Account"
            linkText="Go to Dashboard"
            linkTo="/dashboard"
            onButtonClick={() => {
              navigate(0);
            }}
          />
        )}
        {errorMessage && <ErrorScreen errorMessage={errorMessage} />}

        {!isSuccess && <div className="h-full w-165" id={configName}></div>}
      </div>
    </PageLayout>
  );
};
