import { motion } from 'framer-motion';
import { FaExclamationCircle } from 'react-icons/fa';

export const ErrorScreen = ({ errorMessage = 'Something went wrong!' }: any) => {
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      className=" flex flex-col items-center justify-center bg-gray-100 p-4"
      initial="hidden"
      animate="visible"
      variants={fadeIn}
    >
      <FaExclamationCircle className="mb-4 h-24 w-24 text-red-500" />
      <p className="text-center   font-semibold text-gray-500">{errorMessage}</p>
    </motion.div>
  );
};
