import { z } from 'zod';
import { login } from '../common/constants/login.constants';

const { password } = login.loginForm.fields;

export const loginFormSchema = z.object({
  email: z
    .string()
    .refine((value) => value !== '', 'Please complete all mandatory fields marked with *')
    .refine(
      (value) => /^[^@]+@[^@]+\.[^@]+$/.test(value),
      'Invalid email address entered, please update.'
    ),
  password: z.string().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/, {
    message: password.error,
  }),
});

export const forgotPasswordFormSchema = z.object({
  email: z
    .string()
    .refine((value) => value !== '', 'Please complete all mandatory fields marked with *')
    .refine(
      (value) => /^[^@]+@[^@]+\.[^@]+$/.test(value),
      'Invalid email address entered, please update.'
    ),
});
