import { useMutation, MutationOptions } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useAuthStore } from 'domain/state';
import * as api from './api';

export function useCreateInvitationQuery(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, any>
) {
  const setContactDetails = useAuthStore((state) => state.setContactDetails);

  return useMutation(
    async (data) => {
      return await api.createInvitation(data);
    },
    {
      onSuccess: (data) => {
        setContactDetails(data.data);
      },
      ...options,
    }
  );
}

export function useValidateInvitation(options?: MutationOptions<AxiosResponse<any>, AxiosError, any>) {
  const setContactDetails = useAuthStore((state) => state.setContactDetails);

  return useMutation(
    async (data) => {
      return await api.validateInvitation(data);
    },
    {
      onSuccess: (data) => {
        setContactDetails(data.data);
      },
      ...options,
    }
  );
}
