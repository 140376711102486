import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Button } from 'domain/common/components';

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

interface ConnectionSuccessProps {
  message: string;
  highlightedText: string;
  buttonText: string;
  linkText: string;
  linkTo: string;
  onButtonClick?: () => void;
}

export const ConnectionSuccess: React.FC<ConnectionSuccessProps> = ({
  message,
  highlightedText,
  buttonText,
  linkText,
  linkTo,
  onButtonClick,
}) => {
  return (
    <motion.div
      className="flex  w-130 flex-col items-center justify-center rounded-lg bg-white p-14"
      initial="hidden"
      animate="visible"
      variants={fadeIn}
    >
      <div className="mb-6 text-center">
        <p className="mb-4 text-xl font-medium text-gray-700">
          {message} <span className="font-bold">{highlightedText}</span>
        </p>

        <Button intent="green" type="submit" className="mx-3 h-full w-full" onClick={onButtonClick}>
          {buttonText}
        </Button>
      </div>
      <Link to={linkTo} className="text-black hover:underline focus:outline-none">
        {linkText}
      </Link>
    </motion.div>
  );
};
