import { useMutation, MutationOptions } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';
import * as api from './api';
import { handleLogout } from 'domain/utils';
export function useCreateFastLinkQuery(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, string>
) {
  return useMutation((authToken: string) => api.createFastLink(authToken), {
    onError: () => handleLogout(),
    ...options,
  });
}
