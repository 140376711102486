import { PageLayout, Text } from 'domain/common/components';
import { useLocation } from 'react-router-dom';
import { ExistingCustomContainer } from './existing-custom.container';
import { HeroSectionSlider } from './components/reviews.component';
import { HeroSection } from './components/home-hero.component';
import { SignInContainer } from './log-in.container';
import { useValidateInvitation } from 'domain/queries';
import { useEffect } from 'react';
import { useAuthStore } from 'domain/state';
import { DemoVideo } from 'domain/assets';
import { InfoCard } from './components';
import { homepage } from 'domain/common/constants';

const CallUsNow = () => {
  return (
    <div className="flex flex-col items-center justify-center ">
      <Text intent="body" className="mb-5 text-white">
        Having trouble logging in or registering?
      </Text>
      <a
        href="tel:1300957721"
        className="items-center justify-center rounded-lg bg-zembl-violet p-5 px-14  text-1.25 font-166 leading-1.625 tracking-0.05"
      >
        Call 1300 957 721
      </a>
    </div>
  );
};

const VideoWrapper = () => {
  return (
    <div className="flex  items-center justify-center">
      <video className="w-140" src={DemoVideo} autoPlay loop muted />
    </div>
  );
};

export const HomeContentSection = () => {
  const { whyMindMyBusiness } = homepage;
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');

  const { mutateAsync: validateInvitation } = useValidateInvitation({});
  const setUrlToken = useAuthStore((state) => state.setUrlToken);
  const resetCache = useAuthStore((state) => state.resetCache);

  useEffect(() => {
    if (token) {
      validateInvitation({ token });
      setUrlToken(token);
    } else {
      resetCache();
    }
  }, [token]);

  return (
    <section className="flex flex-col gap-4 py-6 xs:gap-8 xs:py-12" id="how-it-works">
      <Text intent="heading-2">{whyMindMyBusiness.heading}</Text>
      <VideoWrapper />
      <section className="flex flex-wrap justify-center gap-3 xs:gap-5">
        {whyMindMyBusiness.cards.map((card) => (
          <InfoCard key={card.title} {...card} />
        ))}
      </section>
    </section>
  );
};
const FormSection = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasToken = queryParams.has('token');

  return (
    <section className="mb-8  content-center">
      <div className="flex flex-col">
        <div id="auth-container" className="mb-8  flex space-x-8 xs:flex-col   lg:flex-row lg:px-0">
          {hasToken ? <ExistingCustomContainer /> : <SignInContainer />}
          <div className="items-center justify-center">{HeroSectionSlider()}</div>
        </div>
        <CallUsNow />
      </div>
    </section>
  );
};
export const HomePage = () => {
  return (
    <PageLayout>
      <div id="top" className=" flex w-full flex-col content-center items-center bg-zembl-forest">
        <HeroSection />
        <FormSection />
      </div>
      <HomeContentSection />
    </PageLayout>
  );
};
